import NextImage from "@components/NextImage";
import {useTranslation} from "ni18n";
import React, {memo, useEffect, useRef, useState} from "react";
import {NEXT_PUBLIC_GOOGLE_API_KEY} from "src/publicEnv";
import {useIsKeyboardUser} from "src/utils/browser-storage/isKeyboardUser";

import {Coordinate} from "../../../store/types";
import {getGoogleMapsStaticImgSrc} from "../../../utils/getGoogleMapsStaticImgSrc";
import {modifyViewportDimension} from "../../../utils/modifyViewportDimension";
import Fade from "../../animations/Fade";
import Glimmer, {GlimmerOffset} from "../Glimmer";
import {useIsMobile} from "src/utils/isMobile";

export type SrcAndAlt = {
  src: string;
  alt?: string;
};

type Props = {
  images: (string | Coordinate | SrcAndAlt)[];
  imageWindowWidth?: number | string;
  imageScaleFactor?: number;
  onFirstImageScroll?: () => void;
  label?: string;
  loading?: boolean;
};

const isCoordinate = (image: Props["images"][number]): image is Coordinate =>
  typeof image === "object" && image && "x" in image && "y" in image;

const ImageCarousel: React.FC<Props> = memo(
  ({
    images,
    imageWindowWidth = "100%",
    imageScaleFactor = 1,
    onFirstImageScroll,
    loading = false,
    label,
  }) => {
    const i18n = useTranslation();
    const isMobile = useIsMobile();

    const isKeyboardUser = useIsKeyboardUser();
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const [scrollEventSent, setScrollEventSent] = useState(false);
    const [isScrollEnd, setIsScrollEnd] = useState<"left" | null | "right">("left");
    const [rightButtonPosition, setRightButtonPosition] = useState(0);

    useEffect(() => {
      const imageContainer = imageContainerRef && imageContainerRef.current;
      if (imageContainer) {
        setRightButtonPosition(imageContainer.clientWidth - 80);
      }
    }, [imageContainerRef?.current?.clientWidth]);

    const sendLocationImageScrollEvent = () => {
      if (!scrollEventSent && onFirstImageScroll) {
        setScrollEventSent(true);
        onFirstImageScroll();
      }
    };

    const isScrollEndListener = () => {
      const imageContainer = imageContainerRef && imageContainerRef.current;
      if (imageContainer) {
        const maxScrollLeft = imageContainer.scrollWidth - imageContainer.clientWidth;
        switch (maxScrollLeft - imageContainer.scrollLeft) {
          case 0:
            setIsScrollEnd("right");
            break;
          case maxScrollLeft:
            setIsScrollEnd("left");
            break;
          default:
            setIsScrollEnd(null);
        }
      }
    };

    const imageWidth = modifyViewportDimension("42vw", x => x * imageScaleFactor);
    const imageHeight = modifyViewportDimension("27vw", x => x * imageScaleFactor);

    useEffect(() => {
      const imageContainer = imageContainerRef && imageContainerRef.current;

      if (scrollEventSent) {
        // @ts-expect-error TS2531: Object is possibly 'null'.
        imageContainer.removeEventListener("scroll", sendLocationImageScrollEvent);
      } else if (imageContainer) {
        imageContainer.addEventListener("scroll", sendLocationImageScrollEvent);
      }
      // @ts-expect-error TS2531: Object is possibly 'null'.
      imageContainer.addEventListener("scroll", isScrollEndListener);

      return () => {
        // @ts-expect-error TS2531: Object is possibly 'null'.
        imageContainer.removeEventListener("scroll", sendLocationImageScrollEvent);
        // @ts-expect-error TS2531: Object is possibly 'null'.
        imageContainer.removeEventListener("scroll", isScrollEndListener);
      };
    }, [imageContainerRef, scrollEventSent]);

    const getImgSrc = (image: Props["images"][number]) =>
      typeof image === "string"
        ? image
        : "src" in image
        ? image.src
        : getGoogleMapsStaticImgSrc(image, NEXT_PUBLIC_GOOGLE_API_KEY || "");

    const locationImages = images.map((img, i) => (
      <li
        key={`img-carousel-${i}`}
        className="mr2 br1 pos-r"
        style={{minWidth: isCoordinate(img) ? imageHeight : imageWidth, minHeight: imageHeight}}
      >
        {loading ? (
          <Glimmer offset={GlimmerOffset.SM} />
        ) : (
          <NextImage
            className="br1 wa obfc"
            src={getImgSrc(img)}
            layout="fill"
            priority={i < 3}
            // @ts-expect-error TS2339: Property 'alt' does not exist on type 'string | Coordinate | SrcAndAlt'.
            alt={img?.alt || ""}
          />
        )}
      </li>
    ));

    const imagesContainerWidth = modifyViewportDimension(
      imageWidth,
      x => (x * images.length + 35) * imageScaleFactor,
    );

    const [leftIsFocused, setLeftIsFocused] = useState(false);
    const [rightIsFocused, setRightIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    // Always show for keyboard users mobile or desktop, otherwise show if images are hovered or buttons are focused and not mobile
    const showLeftButton = isKeyboardUser || ((isHovered || leftIsFocused) && !isMobile);
    const showRightButton = isKeyboardUser || ((isHovered || rightIsFocused) && !isMobile);

    const getImageRenderedWidth = () =>
      (window.innerWidth * parseInt(imageWidth.replace("vw", ""))) / 100 + 5;

    const scrollRight = () => {
      sendLocationImageScrollEvent();
      // @ts-expect-error TS2531: Object is possibly 'null'.
      imageContainerRef.current.scrollLeft += getImageRenderedWidth();
    };

    const scrollLeft = () => {
      // @ts-expect-error TS2531: Object is possibly 'null'.
      imageContainerRef.current.scrollLeft -= getImageRenderedWidth();
    };

    return (
      <section
        aria-roledescription="carousel"
        aria-label={label}
        className="mt2 mb0 hide-scroll-bars pos-r"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          ref={imageContainerRef}
          className="ovf-x-a"
          style={{scrollBehavior: "smooth", width: imageWindowWidth}}
        >
          <ul
            id="carousel"
            className="df pl40"
            style={{
              scrollBehavior: "smooth",
              marginLeft: -160,
              width: imagesContainerWidth,
              maxWidth: "100vw",
            }}
          >
            {locationImages}
            <div className="minw1" />
          </ul>
          <Fade in={showLeftButton && isScrollEnd !== "left"} unmountOnExit timeout={150} appear>
            <button
              aria-controls="carousel"
              onClick={scrollLeft}
              style={{left: 5}}
              className="pos-a top0 bottom0 ma br50 h10 w10 df aic jcc pl1 brdn otn cp"
              onFocus={() => setLeftIsFocused(true)}
              onBlur={() => setLeftIsFocused(false)}
              aria-label={i18n.t("Scroll left")}
            >
              <span className="cIcon-dropdown-arrow-up rotate270 gray800 fs18" aria-hidden />
            </button>
          </Fade>
          <Fade in={showRightButton && isScrollEnd !== "right"} unmountOnExit timeout={150} appear>
            <button
              aria-controls="carousel"
              onClick={scrollRight}
              className="pos-a top0 bottom0 ma br50 h10 w10 df aic jcc pr1 brdn otn cp"
              onFocus={() => setRightIsFocused(true)}
              onBlur={() => setRightIsFocused(false)}
              aria-label={i18n.t("Scroll right")}
              style={{left: rightButtonPosition}}
            >
              <span className="cIcon-dropdown-arrow-up rotate90 gray800 fs18" aria-hidden />
            </button>
          </Fade>
        </div>
        <span id="widget" />
      </section>
    );
  },
);

ImageCarousel.displayName = "ImageCarousel";

export default ImageCarousel;
